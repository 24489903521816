.pin {
    color: #333;
    text-align: center;
    line-height: 2;
    font-weight: 500;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    width: 95px;
    height: 35px;
    padding: 0px;
    background: #fff;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.pin.active {
    position: absolute;
    width: 95px;
    height: 35px;
    padding: 0px;
    background: #00A9C3;
    color:#fff;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    z-index: 5;
}
