.wrap {
    position: relative;
    top: 65px;
    margin: 0 auto;
}

.roadmap {
    width: 100%;
    height: 100%;
    
}


@media (max-width:1240px) {
    .wrap {
        min-width: 1100px;
    }
}


@media (max-width:814px) {
    .wrap {
        min-width: 360px;
        top:0px;
        background-size: cover;
        background-position: center center;
    }

    .roadmap {
        display: none;
    }
    .card {
        display: none;
    }

}