.card {
  position: absolute;
  width: 100%;
  z-index: 6;
}

.startcard {
  background-color: #fff;
  max-width: 720px;
  width: 90%;
  height: 170px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 3;
  position: relative;
  bottom: 130px;
}

.startcard__img {
  padding: 10px;
  width: 144px;
  height: 144px;
}

.startcard__txt {
  width: 70%;
  height: 80%;
  color: #333;
  margin: 20px;
  font-weight: 300;
}

.startcard__txt > h3 {
  font-size: 18px;
  font-weight: 700;
}

.startcard__txt > p {
  font-size: 16px;
  margin-top: 15px;
  height: 45%;
}

.card_btn {
  background-color: #00A9C2;
  color: #fff;
  width: 95%;
  border: none;
  border-radius: 22px;
  height: 30%;
  cursor: pointer;
}

@media (max-width:814px) {
  .card {
    min-width: 360px;
  }
  
  .startcard {
    height: 145px;
    bottom: 170px;
  }

  .startcard__img {
    padding: 10px;
  }

  .startcard__txt {
    word-break: keep-all;
  }

  .startcard__txt > h3 {
    font-size: 16px;
  }

  .startcard__txt > p {
    font-size: 14px;
  }
}