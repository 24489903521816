.footer {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: #333;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    z-index: 99;
}

.store-title {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 14px;
}

.store-list {
    display: flex;
    margin-bottom:30px;
}

.store-list > li {
    padding: 16px;
    font-weight: 500;
    cursor: pointer;
}

.store-list > li > a {
    color:#fff;
    text-decoration: none;
}

.store-text {
    font-size: 12px;
    margin-bottom: 20px;
}

.store-copy {
    opacity: 0.5;
    font-size: 10px;
}

@media (max-width:814px) {
    .footer {
        margin-top: -2px;
        height: 500px;
    }
    
    .store-list {
        flex-direction: column;
        text-align: center;
    }
}