@media (max-width:814px) {

    .storeCardWrap { 
        padding: 5%;
        padding-top: 20vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .storeCard {
        color: #333;
        margin-bottom: 5%;
        padding: 1rem;
        background-color: rgba(255,255,255,.9);
        border-radius: 8px;
        min-width: 31%;
        max-width: 31%;
        flex: 1 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
@media (max-width:614px) {
    .storeCard {
        min-width: 47%;
    }
    .storeCardWrap {
    padding-top: 30vw;
    }
}

.storeCard__img {
    height: 140px;
    width: auto;
    padding: 5%;
}

.storeCard__name {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 8px;
}
.storeCard__desc {
    font-size: 0.9rem;
    color: #888;
    line-height: 1.4;
}