.header {
    position: fixed;
    top: 0px;
    z-index: 999;
    background-color: #fff;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 2px 7px rgba(0,0,0,.3);
}

.header__logo {
    margin-left: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header__logo > img {
    height: 32px;
}

.header__img {
    margin-right: 80px;
    display: flex;
    align-items: center;
}

.header__img > img {
    width: 100%;
    height: 100%;
}

@media (max-width:814px) {
    .header_wrap {
        display: flex;
        justify-content: center;
    }
    
    .header {
        min-width: 360px;
        width: 90%;
        height: 45px;
        border-radius: 10px;
        top: 30px;
    }

    .header__img {
        width: 45px;
        height: 45px;
    }

    .header__logo > img {
        height: 25px;
    }

    .header__logo {
        margin-left: 20px;
    }

    .header__img {
        margin-right: 20px;
    }
}
